/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PrismicText, usePrismicDocumentsByIDs } from '@prismicio/react';
import BuyNowLinks from '../common/BuyNowLinks';
import Loading from '../common/Loading';

export default function SliceAlbum({ slice }) {
  const [documents, status] = usePrismicDocumentsByIDs(slice.items.map((item) => (item.albums.id)));
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  const entries = {};
  documents.results.forEach((result) => {
    entries[result.id] = result;
  });
  return (
    <section className="SliceAlbum">
      <Container>
        <Row>
          <Col>
            <h2><PrismicText field={slice.primary.title} /></h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          {slice.items.map((item) => (
            <Col key={item.albums.id} md={4}>
              <div className="album">
                {entries[item.albums.id] !== undefined
                  ? (
                    <div>
                      <h3><Link to={`/album/${entries[item.albums.id].uid}`}>{entries[item.albums.id].data.title}</Link></h3>
                      <Link to={`/album/${entries[item.albums.id].uid}`}>
                        <img
                          src={entries[item.albums.id].data.cover.url}
                          alt={entries[item.albums.id].data.title}
                        />
                      </Link>
                      <BuyNowLinks marketPlace={entries[item.albums.id].data.market_place} />
                    </div>
                  ) : null}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <hr />
    </section>
  );
}
SliceAlbum.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

/*
class SliceAlbum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: {},
    };
  }

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;
    const { slice } = this.props;
    const ids = slice.items.map((item) => (item.albums.id));
    Prismic.api(apiEndpoint).then((api) => {
      api.getByIDs(ids).then((response) => {
        const entries = {};
        response.results.forEach((result) => {
          entries[result.id] = result;
        });
        this.setState({ entries });
      });
    });
  }

  render() {
    const { slice } = this.props;
    const { entries } = this.state;
    return (
      <section className="SliceAlbum">
        <Container>
          <Row>
            <Col>
              <h2>{RichText.asText(slice.primary.title)}</h2>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            {slice.items.map((item) => (
              <Col key={item.albums.id} md={4}>
                <div className="album">
                  {entries[item.albums.id] !== undefined
                    ? (
                      <div>
                        <h3><Link to={`/album/${entries[item.albums.id].uid}`}>{entries[item.albums.id].data.title}</Link></h3>
                        <Link to={`/album/${entries[item.albums.id].uid}`}>
                          <img
                            src={entries[item.albums.id].data.cover.url}
                            alt={entries[item.albums.id].data.title}
                          />
                        </Link>
                        <BuyNowLinks marketPlace={entries[item.albums.id].data.market_place} />
                      </div>
                    ) : null}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <hr />
      </section>
    );
  }
}
SliceAlbum.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceAlbum;
*/
