/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

function SliceAction(props) {
  const { slice } = props;
  let coverStyle = {};
  if (
    slice.primary.banner !== undefined
    && slice.primary.banner.url !== undefined
  ) {
    coverStyle = {
      backgroundImage: `url("${slice.primary.banner.url}")`,
    };
  }
  return (
    <section style={coverStyle} className="SliceAction">
      <div className="overlay">
        <Container>
          <Row>
            {slice.items.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={i} lg={6}>
                <PrismicRichText field={item.content} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
}
SliceAction.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceAction;
