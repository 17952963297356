/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { usePrismicDocumentsByIDs } from '@prismicio/react';
import Loading from './Loading';

export default function SongArtist({ artists }) {
  const ids = [];
  artists.forEach((artist) => {
    if (artist.artist.id !== undefined) ids.push(artist.artist.id);
  });
  const [artistEntries, status] = usePrismicDocumentsByIDs(ids);
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  const entries = {};
  artistEntries.results.forEach((doc) => {
    entries[doc.id] = doc;
  });

  return (
    <span>
      {artists.map((artist, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i}>
          {entries[artist.artist.id] !== undefined ? (
            <Link to={`/artist/${entries[artist.artist.id].uid}`}>{entries[artist.artist.id].data.name}</Link>
          ) : null }
        </span>
      )).reduce((prev, curr) => [prev, ', ', curr])}
    </span>
  );
}
SongArtist.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object).isRequired,
};
/*
class SongArtist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: {},
    };
  }

  componentDidMount() {
    const { artists } = this.props;
    // console.log(artists);
    const ids = [];
    artists.forEach((artist) => {
      if (artist.artist.id !== undefined) ids.push(artist.artist.id);
    });
    // console.log(ids);
    if (ids.length > 0) {
      const apiEndpoint = APIENDPOINT;
      Prismic.api(apiEndpoint).then((api) => {
        api.getByIDs(ids).then((response) => {
          const entries = {};
          response.results.forEach((result) => {
            // console.log(result);
            entries[result.id] = result;
          });
          this.setState({ entries });
        });
      });
    }
  }

  render() {
    const { artists } = this.props;
    const { entries } = this.state;
    return (
      <span>
        {artists.map((artist, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>
            {entries[artist.artist.id] !== undefined ? (
              <Link to={`/artist/${entries[artist.artist.id].uid}`}>{entries[artist.artist.id].data.name}</Link>
            ) : null }
          </span>
        )).reduce((prev, curr) => [prev, ', ', curr])}
      </span>
    );
  }
}

SongArtist.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object),
};
SongArtist.defaultProps = {
  artists: [],
};

export default SongArtist;
*/
