import React from 'react';
import PropTypes from 'prop-types';

function ResponsiveVideo(props) {
  const {
    video: {
      height,
      width,
      html,
    },
  } = props;
  const vidoeRatio = Math.round((
    height / width
  ) * 10000) / 100;
  const videoWrapperStyle = {
    paddingBottom: `${vidoeRatio}%`,
  };
  // console.log(props);
  return (
    <div>
      {html !== undefined ? (
        <div
          className="videoWrapper"
          style={videoWrapperStyle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : null }
    </div>
  );
}

ResponsiveVideo.propTypes = {
  video: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    html: PropTypes.string,
  }).isRequired,
};

export default ResponsiveVideo;
