/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import './scss/main.scss';

import { usePrismicDocumentByUID, usePrismicDocumentsByType } from '@prismicio/react';
import {
  BrowserRouter, Link, Route, Routes,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { asText } from '@prismicio/client';
import Home from './Home';
import Album from './Album';
import Artist from './Artist';
import ScrollToAnchor from './common/ScrollToAnchor.';

import { TITLE_SUFFIX, URL_ROOT } from './config/constant';
import Loading from './common/Loading';

export default function App() {
  const [home, homeStatus] = usePrismicDocumentByUID('home', 'home');
  const [menus, menuStatus] = usePrismicDocumentsByType('menu');
  if (
    homeStatus.state !== 'loaded'
    || menuStatus.state !== 'loaded'
  ) return <Loading />;
  const logo = home.data.logo.url;
  const siteName = home.data.site_name;

  const socialTitle = TITLE_SUFFIX;
  const socialURL = `${URL_ROOT}/`;
  const socialImage = home.data.seo_image.url;
  const socialDescription = asText(home.data.seo_description);
  const nav = {};
  menus.results.forEach((menu) => {
    nav[menu.uid] = menu;
  });

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />

        <title>{TITLE_SUFFIX}</title>
        <link rel="canonical" href={socialTitle} />
        <meta name="description" content={socialDescription} />

        <meta property="og:title" content={socialTitle} />
        <meta property="og:image" content={socialImage} />
        <meta property="og:url" content={socialURL} />
        <meta property="og:description" content={socialDescription} />

        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:image" content={socialImage} />
        <meta name="twitter:card" content={socialURL} />
      </Helmet>
      <BrowserRouter>
        <ScrollToAnchor />
        <Navbar expand="lg" fixed="top" data-bs-theme="dark">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Navbar.Brand className="d-none d-xs-block d-sm-block d-md-block d-lg-none">
                <Link to="/">
                  <img src={logo} alt={siteName} />
                </Link>
              </Navbar.Brand>
              {nav['global-left'].data.body.map((item, i) => (
                <Link key={i} className="nav-link" to={item.primary.url}>{item.primary.label}</Link>
              ))}
              <Navbar.Brand className="d-none d-lg-block d-xl-block">
                <Link to="/">
                  <img src={logo} alt={siteName} />
                </Link>
              </Navbar.Brand>
              {nav['global-right'].data.body.map((item, i) => (
                <Link key={i} className="nav-link" to={item.primary.url}>{item.primary.label}</Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/album/:uid" element={<Album />} />
          <Route path="/artist/:uid" element={<Artist />} />
        </Routes>
      </BrowserRouter>
      <div className="footer">
        Newtown Records, Copyright
        {' '}
        {new Date().getFullYear()}
      </div>
    </div>
  );
}
