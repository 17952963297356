/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { usePrismicDocumentsByIDs } from '@prismicio/react';
import Loading from './Loading';

export default function SongFeaturingArtist({ artists }) {
  const ids = [];
  artists.forEach((artist) => {
    if (artist.feature_artist.id !== undefined) ids.push(artist.feature_artist.id);
  });
  const [documents, status] = usePrismicDocumentsByIDs(ids);
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  const entries = {};
  documents.results.forEach((doc) => {
    entries[doc.id] = doc;
  });
  const artistsFixed = [];
  artists.forEach((artist) => {
    if (artist.feature_artist.id !== undefined) artistsFixed.push(artist);
  });
  return (
    <span>
      {artistsFixed.length > 0 ? (
        <span>
          {' '}
          (Featuring:
          {' '}
          {artistsFixed.map((artist, i) => (
          // eslint-disable-next-line react/no-array-index-key
            <span key={i}>
              {entries[artist.feature_artist.id] !== undefined ? (
                <Link to={`/artist/${entries[artist.feature_artist.id].uid}`}>{entries[artist.feature_artist.id].data.name}</Link>
              ) : null }
            </span>
          )).reduce((prev, curr) => [prev, ', ', curr])}
          )
        </span>
      ) : null }
    </span>
  );
}
SongFeaturingArtist.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object).isRequired,
};

/*
class SongFeaturingArtist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: {},
    };
  }

  componentDidMount() {
    const { artists } = this.props;
    // console.log(artists);
    const ids = [];
    artists.forEach((artist) => {
      if (artist.feature_artist.id !== undefined) ids.push(artist.feature_artist.id);
    });
    // console.log(ids);
    if (ids.length > 0) {
      const apiEndpoint = APIENDPOINT;
      Prismic.api(apiEndpoint).then((api) => {
        api.getByIDs(ids).then((response) => {
          const entries = {};
          response.results.forEach((result) => {
            // console.log(result);
            entries[result.id] = result;
          });
          this.setState({ entries });
        });
      });
    }
  }

  render() {
    const { artists } = this.props;
    const { entries } = this.state;
    const artistsFixed = [];
    artists.forEach((artist) => {
      if (artist.feature_artist.id !== undefined) artistsFixed.push(artist);
    });
    // console.log(artists);
    return (
      <span>
        {artistsFixed.length > 0 ? (
          <span>
            {' '}
            (Featuring:
            {' '}
            {artistsFixed.map((artist, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={i}>
                {entries[artist.feature_artist.id] !== undefined ? (
                  <Link to={`/artist/${entries[artist.feature_artist.id].uid}`}>{entries[artist.feature_artist.id].data.name}</Link>
                ) : null }
              </span>
            )).reduce((prev, curr) => [prev, ', ', curr])}
            )
          </span>
        ) : null }
      </span>
    );
  }
}

SongFeaturingArtist.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object),
};
SongFeaturingArtist.defaultProps = {
  artists: [],
};

export default SongFeaturingArtist;
*/
