import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { useLocation, useParams } from 'react-router-dom';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { Helmet } from 'react-helmet-async';
import { TITLE_SUFFIX, URL_ROOT } from './config/constant';
import AlbumSong from './common/Song';
import BuyNowLinks from './common/BuyNowLinks';
import Loading from './common/Loading';

export default function Album() {
  const { uid } = useParams();
  const { pathname } = useLocation();
  const [album, status] = usePrismicDocumentByUID('albums', uid);
  if (
    status.state !== 'loaded'
  ) return <Loading />;

  const songIds = [];
  album.data.songs.forEach((song) => {
    if (song.song.id !== undefined) songIds.push(song.song.id);
  });

  const className = pathname.split('/').join(' ');

  const socialTitle = `${album.data.title} - ${TITLE_SUFFIX}`;
  const socialURL = `${URL_ROOT}${pathname}`;
  const socialImage = album.data.cover.url;

  return (
    <div className="nav-spacer">
      <Helmet>
        <title>{socialTitle}</title>
        <link rel="canonical" href={socialURL} />

        <meta property="og:title" content={socialTitle} />
        <meta property="og:image" content={socialImage} />
        <meta property="og:url" content={socialURL} />

        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:image" content={socialImage} />
        <meta name="twitter:card" content={socialURL} />
      </Helmet>
      <Container className={className}>
        <Row>
          <Col md={4}>
            <img src={album.data.cover.url} alt={album.data.title} />
            <BuyNowLinks marketPlace={album.data.market_place} />
          </Col>
          <Col>
            <h1>{album.data.title}</h1>
            {songIds.map((songId) => (
              <AlbumSong songId={songId} key={songId} />
            ))}
          </Col>
        </Row>
      </Container>

    </div>
  );
}
