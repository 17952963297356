/* eslint-disable react/forbid-prop-types */
import React from 'react';
import slugify from 'slugify';
import { usePrismicDocumentByUID } from '@prismicio/react';
import PropTypes from 'prop-types';

import { asText } from '@prismicio/client';
import { useLocation } from 'react-router-dom';
import SliceAction from './Slices/SliceAction';
import SliceAlbum from './Slices/SliceAlbum';
import SliceArtist from './Slices/SliceArtist';
import SliceContact from './Slices/SliceContact';
import SliceGallery from './Slices/SliceGallery';
import SliceVideo from './Slices/SliceVideo';
import Loading from './common/Loading';

function RenderSlice({ slices }) {
  if (slices.length <= 0) return null;
  return slices.map((slice) => {
    let result = null;
    switch (slice.slice_type) {
      case 'action_s_':
        result = (
          <SliceAction slice={slice} />
        );
        break;
      case 'album_collection':
        result = (
          <SliceAlbum slice={slice} />
        );
        break;
      case 'artist_collection':
        result = (
          <SliceArtist slice={slice} />
        );
        break;
      case 'contact_us':
        result = (
          <SliceContact slice={slice} />
        );
        break;
      case 'video':
        result = (
          <SliceVideo slice={slice} />
        );
        break;
      case 'gallery':
        result = (
          <SliceGallery slice={slice} />
        );
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(slice.slice_type);
        // return null;
    }
    if (result !== null) {
      const scrollId = `${slugify(asText(slice.primary.title))}`;
      return (
        <div key={scrollId} id={scrollId}>
          <div>
            {result}
          </div>
        </div>
      );
    }
    return null;
  });
}

RenderSlice.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default function Home() {
  const [home, status] = usePrismicDocumentByUID('home', 'home');
  const location = useLocation();
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  const slices = home.data.body;
  if (location.hash.replace('#', '') !== '') {
    setTimeout(() => {
      document.getElementById(location.hash.replace('#', '')).scrollIntoView();
    }, 500);
  }
  return (
    <div className="home">
      <RenderSlice slices={slices} />
    </div>
  );
}
/*
class Home extends React.Component {

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;

    const { location: { hash } } = this.props;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(Prismic.Predicates.at('document.type', 'home')).then((response) => {
        if (response) {
          this.setState({
            slices: response.results[0].data.body,
          }, () => {
            if (hash.replace('#', '') !== '') {
              setTimeout(() => {
                document.getElementById(hash.replace('#', '')).scrollIntoView();
              }, 500);
            }
          });
        }
      });
    });
  }

  renderSlices() {
    const { slices } = this.state;
    if (slices.length <= 0) return null;
    return slices.map((slice) => {
      let result = null;
      switch (slice.slice_type) {
        case 'action_s_':
          result = (
            <SliceAction slice={slice} />
          );
          break;
        case 'album_collection':
          result = (
            <SliceAlbum slice={slice} />
          );
          break;
        case 'artist_collection':
          result = (
            <SliceArtist slice={slice} />
          );
          break;
        case 'contact_us':
          result = (
            <SliceContact slice={slice} />
          );
          break;
        case 'video':
          result = (
            <SliceVideo slice={slice} />
          );
          break;
        case 'gallery':
          result = (
            <SliceGallery slice={slice} />
          );
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(slice.slice_type);
          // return null;
      }
      if (result !== null) {
        const scrollId = `${slugify(RichText.asText(slice.primary.title))}`;
        return (
          <div key={scrollId} id={scrollId}>
            <div>
              {result}
            </div>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <div className="home">
        {this.renderSlices()}
      </div>
    );
  }
}

Home.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

Home.defaultProps = {
  location: {
    hash: '',
  },
};
export default Home;
*/
