/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import { Row, Col } from 'react-bootstrap';

import { usePrismicDocumentByID } from '@prismicio/react';
import SongArtist from './SongArtist';
import SongFeaturingArtist from './SongFeaturingArtist';
import Loading from './Loading';

export default function Song({
  songId, hideArtist, hideTitle,
}) {
  const [song, status] = usePrismicDocumentByID(songId);

  if (
    status.state !== 'loaded'
  ) return <Loading />;
  return (
    <div className="songRow">
      <Row>
        {!hideTitle || !hideArtist ? (
          <Col xs={12} lg={4}>
            {!hideTitle ? (
              <div>
                <span className="songName">{song.data.name}</span>
              </div>
            ) : null }
            {!hideArtist && song.data.artists !== undefined ? (
              <div className="artistName">
                <SongArtist artists={song.data.artists} />
                {!hideArtist && song.data.featuring !== undefined ? (
                  <span className="artistName">
                    <SongFeaturingArtist artists={song.data.featuring} />
                  </span>
                ) : null }
              </div>
            ) : null }
          </Col>
        ) : null }
        <Col>
          {song.data.track !== undefined && song.data.track.url !== undefined ? (
            <AudioPlayer
              src={song.data.track.url}
              layout="horizontal"
            />
          ) : null }
        </Col>
      </Row>
    </div>
  );
}
Song.propTypes = {
  songId: PropTypes.string,
  hideArtist: PropTypes.bool,
  hideTitle: PropTypes.bool,
};
Song.defaultProps = {
  songId: '',
  hideArtist: false,
  hideTitle: false,
};
/*
class Song extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      song: {
        data: {
          name: [],
          track: {},
        },
      },
    };
  }

  componentDidMount() {
    const { songId } = this.props;
    if (songId !== '' && songId !== undefined) {
      const apiEndpoint = APIENDPOINT;
      Prismic.api(apiEndpoint).then((api) => {
        api.getByID(songId).then((document) => {
          // console.log(document);
          this.setState({ song: document });
        });
      });
    }
  }

  render() {
    const { hideArtist, hideTitle } = this.props;
    let { song: propSong } = this.props;
    if (propSong.data.name === '') {
      ({ song: propSong } = this.state);
    }
    const song = propSong;
    // console.log(song);
    return (
      <div className="songRow">
        <Row>
          {!hideTitle || !hideArtist ? (
            <Col xs={12} lg={4}>
              {!hideTitle ? (
                <div>
                  <span className="songName">{song.data.name}</span>
                </div>
              ) : null }
              {!hideArtist && song.data.artists !== undefined ? (
                <div className="artistName">
                  <SongArtist artists={song.data.artists} />
                  {!hideArtist && song.data.featuring !== undefined ? (
                    <span className="artistName">
                      <SongFeaturingArtist artists={song.data.featuring} />
                    </span>
                  ) : null }
                </div>
              ) : null }
            </Col>
          ) : null }
          <Col>
            {song.data.track.url !== undefined ? (
              <AudioPlayer
                src={song.data.track.url}
              />
            ) : null }
          </Col>
        </Row>
      </div>
    );
  }
}

Song.propTypes = {
  songId: PropTypes.string,
  song: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      track: PropTypes.object,
    }),
  }),
  hideArtist: PropTypes.bool,
  hideTitle: PropTypes.bool,
};
Song.defaultProps = {
  songId: '',
  song: {
    data: {
      name: '',
      track: {},
    },
  },
  hideArtist: false,
  hideTitle: false,
};
export default Song;
*/
