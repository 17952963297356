import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { Helmet } from 'react-helmet-async';

import { TITLE_SUFFIX, URL_ROOT } from './config/constant';
import ArtistSidebar from './ArtistSidebar';
import ArtistFeatureSong from './ArtistFeatureSong';
import ResponsiveVideo from './common/ResponsiveVideo';
import Loading from './common/Loading';

export default function Artist() {
  const { uid } = useParams();
  const [artist, status] = usePrismicDocumentByUID('artists', uid);
  const { pathname } = useLocation();
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  const className = pathname.split('/').join(' ');
  // console.log(match);

  const socialTitle = `${artist.data.name} - ${TITLE_SUFFIX}`;
  const socialURL = `${URL_ROOT}${pathname}`;
  const socialImage = artist.data.headshot.url;
  // console.log(artist.data);
  return (
    <div className="nav-spacer">
      <Helmet>
        <title>{socialTitle}</title>
        <link rel="canonical" href={socialURL} />

        <meta property="og:title" content={socialTitle} />
        <meta property="og:image" content={socialImage} />
        <meta property="og:url" content={socialURL} />

        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:image" content={socialImage} />
        <meta name="twitter:card" content={socialURL} />
      </Helmet>
      <Container className={className}>
        <Row>
          <Col>
            <h1>{artist.data.name}</h1>
            {artist.data.video.html !== undefined ? (
              <ResponsiveVideo video={artist.data.video} />
            ) : null }
          </Col>
        </Row>
        <Row>
          {artist.data.bio.length > 0 || artist.data.body.length > 0 ? (
            <Col md="8">
              {artist.data.headshot.url !== undefined && artist.data.show_headshot === true ? (
                <div className="headshot"><img src={artist.data.headshot.url} alt="Heashot" /></div>
              ) : null }
              <div className="bodyText">
                <PrismicRichText field={artist.data.bio} />
              </div>
              {artist.data.body.map((slice, i) => (
              // eslint-disable-next-line react/no-array-index-key
                <ArtistFeatureSong songId={slice.primary.song.id} key={i} />
              ))}
            </Col>
          ) : null }
          <Col>
            {artist.id !== undefined ? (
              <ArtistSidebar artist={artist} />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
