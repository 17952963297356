/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { usePrismicDocumentsByIDs } from '@prismicio/react';
import Song from './common/Song';
import Album from './common/Album';
import Loading from './common/Loading';

export default function ArtistSidebar({ artist }) {
  const albumIds = [];
  artist.data.albums.forEach((album) => {
    if (album.album.id !== undefined) {
      albumIds.push(album.album.id);
    }
  });
  const songIds = [];
  artist.data.songs.forEach((song) => {
    if (song.song.id !== undefined) {
      songIds.push(song.song.id);
    }
  });
  const [songEntries, status] = usePrismicDocumentsByIDs(albumIds.concat(songIds));
  if (
    status.state !== 'loaded'
  ) return <Loading />;

  const songs = {};
  songEntries.results.forEach((doc) => {
    songs[doc.id] = doc;
  });
  return (
    <div className="artistSidebar">
      {albumIds.map((id) => (
        <div key={id}>
          {songs[id] !== undefined ? (
            <Album album={songs[id]} />
          ) : null}
        </div>
      ))}
      {songIds.map((id) => (
        <div key={id}>
          {songs[id] !== undefined ? (
            <Song song={songs[id]} />
          ) : null}
        </div>
      ))}
    </div>
  );
}
ArtistSidebar.propTypes = {
  artist: PropTypes.shape({
    data: PropTypes.shape({
      albums: PropTypes.arrayOf(PropTypes.object),
      songs: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

/*
class ArtistSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albumIds: [],
      songIds: [],
      entries: {},
    };
  }

  componentDidMount() {
    const { artist } = this.props;
    const albumIds = [];
    artist.data.albums.forEach((album) => {
      if (album.album.id !== undefined) {
        albumIds.push(album.album.id);
      }
    });
    const songIds = [];
    artist.data.songs.forEach((song) => {
      if (song.song.id !== undefined) {
        songIds.push(song.song.id);
      }
    });
    this.setState({ albumIds, songIds });
    Prismic.api(APIENDPOINT).then((api) => {
      api.getByIDs(albumIds.concat(songIds)).then((response) => {
        const entries = {};
        response.results.forEach((result) => {
          entries[result.id] = result;
        });
        this.setState({ entries });
      });
    });
  }

  render() {
    const { albumIds, songIds, entries } = this.state;
    return (
      <div className="artistSidebar">
        {albumIds.map((id) => (
          <div key={id}>
            {entries[id] !== undefined ? (
              <Album album={entries[id]} />
            ) : null}
          </div>
        ))}
        {songIds.map((id) => (
          <div key={id}>
            {entries[id] !== undefined ? (
              <Song song={entries[id]} />
            ) : null}
          </div>
        ))}
      </div>
    );
  }
}

ArtistSidebar.propTypes = {
  artist: PropTypes.shape({
    data: PropTypes.shape({
      albums: PropTypes.arrayOf(PropTypes.object),
      songs: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};
ArtistSidebar.defaultProps = {
  artist: {
    data: {
      albums: [],
      songs: [],
    },
  },
};

export default ArtistSidebar;
*/
