/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

class BuyNowLink extends React.Component {
  static renderMarket(market) {
    let marketDiv = null;
    switch (market.market) {
      case 'Amazon':
        marketDiv = (
          <span className="fab fa-amazon" />
        );
        break;
      case 'Google':
        marketDiv = (
          <span className="fab fa-google-play" />
        );
        break;
      case 'Itunes':
        marketDiv = (
          <span className="fab fa-itunes" />
        );
        break;
      default:
    }
    return marketDiv;
  }

  render() {
    const { marketPlace } = this.props;
    // console.log(marketPlace);
    if (marketPlace.length === 0 || marketPlace[0].market === null) return null;
    return (
      <div className="market-places">
        {/**
        <span className="buynow">Purchase Now</span>
        <span className="fas fa-shopping-cart" />
        */}
        {marketPlace.map((market, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>
            <a href={market.market_url.url} target="_blank" rel="noopener noreferrer">
              {BuyNowLink.renderMarket(market)}
              <span className="d-md-none d-lg-none d-xl-inline">
                {' '}
                {market.market}
              </span>
            </a>
          </span>
        ))}
      </div>
    );
  }
}

BuyNowLink.propTypes = {
  marketPlace: PropTypes.arrayOf(PropTypes.object),
};
BuyNowLink.defaultProps = {
  marketPlace: [],
};

export default BuyNowLink;
