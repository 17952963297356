/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { PrismicText } from '@prismicio/react';
import ResponsiveVideo from '../common/ResponsiveVideo';

function SliceVideo({ slice }) {
  return (
    <section className="SliceVideo">
      <Container>
        <Row>
          <Col>
            <h2>
              <PrismicText field={slice.primary.title} />
            </h2>
            <ResponsiveVideo video={slice.primary.video} />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
SliceVideo.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.arrayOf(PropTypes.object),
      video: PropTypes.object,
    }),
  }).isRequired,
};

export default SliceVideo;
