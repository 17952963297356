/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import { usePrismicDocumentsByIDs } from '@prismicio/react';
import Loading from '../common/Loading';

export default function SliceGallery({ slice }) {
  const ids = slice.items.map((item) => (item.gallery.id));
  const [documents, status] = usePrismicDocumentsByIDs(ids);
  if (status.state !== 'loaded') return <Loading />;
  const images = [];
  documents.results.forEach((result) => {
    // console.log(result);
    result.data.images.forEach((image) => {
      // console.log(image.image);
      images.push({
        original: image.image.url,
        thumbnail: image.image.thumb.url,
      });
    });
    // entries[result.id] = result;
  });
  return (
    <section className="SliceGallery">
      <Container>
        <Row>
          <Col>
            <ImageGallery items={images} />
          </Col>
        </Row>
      </Container>
      <hr />
    </section>
  );
}

SliceGallery.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
/*
class SliceGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;
    const { slice } = this.props;
    const ids = slice.items.map((item) => (item.gallery.id));
    Prismic.api(apiEndpoint).then((api) => {
      api.getByIDs(ids).then((response) => {
        const images = [];
        response.results.forEach((result) => {
          // console.log(result);
          result.data.images.forEach((image) => {
            // console.log(image.image);
            images.push({
              original: image.image.url,
              thumbnail: image.image.thumb.url,
            });
          });
          // entries[result.id] = result;
        });
        this.setState({ images });
      });
    });
  }

  render() {
    const { images } = this.state;
    return (
      <section className="SliceGallery">
        <Container>
          <Row>
            <Col>
              <ImageGallery items={images} />
            </Col>
          </Row>
        </Container>
        <hr />
      </section>
    );
  }
}
SliceGallery.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceGallery;
*/
