import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrismicProvider } from '@prismicio/react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
// import * as serviceWorker from './serviceWorker';
import { client } from './config/prismic';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <PrismicProvider client={client}>
        <App />
      </PrismicProvider>
    </HelmetProvider>
  </React.StrictMode>,
);
