/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PrismicText, usePrismicDocumentsByIDs } from '@prismicio/react';
import Loading from '../common/Loading';

export default function SliceArtist({ slice }) {
  const [documents, status] = usePrismicDocumentsByIDs(slice.items.map((item) => (item.artist.id)));
  if (status.state !== 'loaded') return <Loading />;
  const entries = {};
  documents.results.forEach((doc) => {
    entries[doc.id] = doc;
  });
  return (
    <section className="SliceArtist">
      <Container>
        <Row>
          <Col>
            <h2><PrismicText field={slice.primary.title} /></h2>
          </Col>
        </Row>
        <Row>
          {slice.items.map((item) => (
            <Col key={item.artist.id} md={6}>
              <div className="artist">
                {entries[item.artist.id] !== undefined
                  ? (
                    <div>
                      <h3><Link to={`/artist/${entries[item.artist.id].uid}`}>{entries[item.artist.id].data.name}</Link></h3>
                      {entries[item.artist.id].data.headshot.url !== undefined ? (
                        <Link to={`/artist/${entries[item.artist.id].uid}`}>
                          <img
                            src={entries[item.artist.id].data.headshot.url}
                            alt={entries[item.artist.id].data.name}
                          />
                        </Link>
                      ) : null }
                    </div>
                  ) : null}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <hr />
    </section>
  );
}
SliceArtist.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

/*
class SliceArtist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: {},
    };
  }

  componentDidMount() {
    const apiEndpoint = APIENDPOINT;
    const { slice } = this.props;
    // console.log(slice);
    const ids = slice.items.map((item) => (item.artist.id));
    Prismic.api(apiEndpoint).then((api) => {
      api.getByIDs(ids).then((response) => {
        const entries = {};
        response.results.forEach((result) => {
          // console.log(result);
          entries[result.id] = result;
        });
        this.setState({ entries });
      });
    });
  }

  render() {
    const { slice } = this.props;
    const { entries } = this.state;
    return (
      <section className="SliceArtist">
        <Container>
          <Row>
            <Col>
              <h2>{RichText.asText(slice.primary.title)}</h2>
            </Col>
          </Row>
          <Row>
            {slice.items.map((item) => (
              <Col key={item.artist.id} md={6}>
                <div className="artist">
                  {entries[item.artist.id] !== undefined
                    ? (
                      <div>
                        <h3><Link to={`/artist/${entries[item.artist.id].uid}`}>{entries[item.artist.id].data.name}</Link></h3>
                        {entries[item.artist.id].data.headshot.url !== undefined ? (
                          <Link to={`/artist/${entries[item.artist.id].uid}`}>
                            <img
                              src={entries[item.artist.id].data.headshot.url}
                              alt={entries[item.artist.id].data.name}
                            />
                          </Link>
                        ) : null }
                      </div>
                    ) : null}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <hr />
      </section>
    );
  }
}
SliceArtist.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceArtist;
*/
