import React from 'react';

import { PrismicRichText, usePrismicDocumentByID } from '@prismicio/react';
import PropTypes from 'prop-types';
import ResponsiveVideo from './common/ResponsiveVideo';
import Song from './common/Song';
import Loading from './common/Loading';

export default function ArtistFeatureSong({ songId }) {
  // const { uid } = useParams();
  const [song, status] = usePrismicDocumentByID(songId);
  if (
    status.state !== 'loaded'
  ) return <Loading />;
  return (
    <div>
      <ResponsiveVideo video={song.data.video} />
      <h2>{song.data.name}</h2>
      <Song songId={song.id} hideArtist hideTitle />
      <div className="bodyText">
        <PrismicRichText field={song.data.video_description} />
      </div>
    </div>
  );
}

ArtistFeatureSong.propTypes = {
  songId: PropTypes.string.isRequired,
};
