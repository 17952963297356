/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { PrismicText } from '@prismicio/react';

function SliceContact(props) {
  const { slice } = props;
  return (
    <Container className="SliceContact">
      <Row className="justify-content-md-center">
        <Col className="col-lg-6 col-md-8">
          {/**
            <iframe
              className="SliceContact"
              title={RichText.asText(slice.primary.title)}
              src={slice.primary.form.url}
              width="640"
              height="895"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              <Loading />
            </iframe>
          */}
          <h2>
            <PrismicText field={slice.primary.title} />
          </h2>
          <form action={slice.primary.basin_url.url} method="POST">
            <label htmlFor="email-address">
              Email Address
              <input type="email" id="email" name="email" required />
            </label>
            <label htmlFor="name">
              Name
              <input id="name" name="name" required />
            </label>
            <label htmlFor="message">
              Message
              <textarea id="message" name="message" required />
            </label>
            <input type="hidden" name="message2" id="message2" />
            <button type="submit">Submit</button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}
SliceContact.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
  }).isRequired,
};

export default SliceContact;
