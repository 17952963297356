import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BuyNowLinks from './BuyNowLinks';

function Album(props) {
  const { album } = props;
  return (
    <div className="albumRow">
      <h3><Link to={`/album/${album.uid}`}>{album.data.title}</Link></h3>
      <Link to={`/album/${album.uid}`}>
        <img
          src={album.data.cover.url}
          alt={album.data.title}
        />
      </Link>
      <BuyNowLinks marketPlace={album.data.market_place} />
    </div>
  );
}

Album.propTypes = {
  album: PropTypes.shape(),
};
Album.defaultProps = {
  album: {
    uid: '',
    data: {
      title: '',
      cover: {
        url: '',
      },
    },
  },
};

export default Album;
